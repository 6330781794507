import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hi.
    </Text>
    <p>{`I'm Robin Champsaur, a software engineer from Paris, France.`}<br /><br />{`
I currently work `}<a parentName="p" {...{
        "href": "https://www.moqa.studio/"
      }}>{`@Moqa Studio`}</a>{`, a company that I co-founded in 2024.`}<br />{`
We specialize in building AI-powered products, both for our clients and for our own softwares.`}<br /><br /></p>
    <p>{`Writing short tech stories about interesting topics from time to time `}<a parentName="p" {...{
        "href": "https://www.thebuildup.dev/"
      }}>{`here`}</a>{`.`}</p>
    <br />
    <br />
    <h4>{`Past work experiences`}</h4>
    <ul>
      <li parentName="ul">{`2022-2024: AI - Fullstack Software engineer at `}<a parentName="li" {...{
          "href": "https://www.gopigment.com/"
        }}>{`@Pigment`}</a></li>
      <li parentName="ul">{`2020-2022: Fullstack Software engineer at `}<a parentName="li" {...{
          "href": "https://www.gopigment.com/"
        }}>{`@Pigment`}</a></li>
      <li parentName="ul">{`2018-2020: CTO at `}<a parentName="li" {...{
          "href": "https://www.spimed-ai.com/"
        }}>{`@Spimed-AI`}</a></li>
      <li parentName="ul">{`2018: Fullstack Software engineer at `}<a parentName="li" {...{
          "href": "https://www.shift-technology.com/"
        }}>{`@Shift Technology`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      