import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Published projects" mdxType="Title" />
    <h4>{`Current`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://apps.apple.com/us/app/speaking-practice-papagaio/id6448762884"
        }}>{`2022 - Papagaio`}</a><br /><br /></li>
    </ul>
    <h5>{`Discontinued`}</h5>
    <ul>
      <li parentName="ul">{`2017 - Polyword`}</li>
      <li parentName="ul">{`2016 - Sloth News`}</li>
      <li parentName="ul">{`2014 - Sneaky Cat`}</li>
    </ul>
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      